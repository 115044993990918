
import { defineAsyncComponent, defineComponent } from 'vue'
const Vue3DownUpButton = defineAsyncComponent(() => import('@/components/Vue3DownUpButton.vue'))
const google = defineAsyncComponent(() => import('@/components/auth/google.vue'))

export default defineComponent({
  components: {
    Vue3DownUpButton,
    google
  },
  data: () => ({
    showDropdownMenu: false
  }),
  methods: {
    openNav () {
      document.getElementById('mySidenavMain').style.width = '250px'
    },
    closeNav () {
      document.getElementById('mySidenavMain').style.width = '0'
    }
  }
})
